@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,200;6..12,300;6..12,600;6..12,800;6..12,900&family=Nunito:ital,wght@0,500;1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700;900&family=Montserrat:wght@600;900&display=swap');

* {
  position: relative;
  box-sizing: border-box;
}

:root {
  --sidebar-width: 300px;
  --navbar-height-offset: 100px;
}


.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

body {
  font-family: 'Nunito', sans-serif;
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: var(--navbar-height-offset) 0;
  margin: 0;
  /*background: linear-gradient(180deg,rgb(120, 123, 179) 20%,rgb(123, 186, 179) 100%);*/
  background-color: #351a1a;
  background-attachment: fixed;
  color: rgb(202, 91, 87);
  font-size: 1.5em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  width: 100%;
  padding: 0;
}

.App-logo {
  height: 2vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 30s linear;
  }
}

.App-header {
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-logo-row {
  display: flex;
  justify-content: space-between;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
  
.not-found-prompt {
  font-size: 8rem;
  font-weight: bold;
  text-align: center;
}

.btn {
  background: rgb(255, 136, 56);
  border: 6px solid rgb(255, 45, 25);
  padding: 3px 12px;
  margin: 0 5px;
  border-radius: 14px;
  color: #fdff91;
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}

.btn:hover:not(:disabled) {
  background: #e3e3e3;
  color: #b7b7b7;
  border: 6px solid rgb(255, 252, 62);
}

.btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.btn:hover {
  background: #32d99f;
  color: #fff;
}


/* Responsive styles */

@media screen and (max-width: 767px) {

body {
  margin: 0;
  padding: 0;
}
  
} /* /max-width:767px */