/* Responsive styles */


@media screen and (min-width: 1024px) {

  .tab {display: none !important;}

} /* /min-width: 1024px -------------------------------------------------------------- */

@media screen and (min-width: 768px) and (max-width: 1023px) {

  .App > nav + div {
    padding-top: 90px;
    max-width: 100%;
}


} /* /(min-width: 768px) and (max-width: 1023px) -------------------------------------- */


@media screen and (min-width:768px){

.mob {display: none !important;}

} /* /min-width:768px ----------------------------------------------------------------- */

@media screen and (max-width:1023px){
  
* {box-sizing:border-box;max-width:100%;}
table {table-layout:fixed; width:100%;}
img { max-width:100%; height:auto; image-rendering: -webkit-optimize-contrast;}
body {margin: 0px;padding: 0px;}
iframe { max-width:100% !important;}
object { max-width:100% !important;}
.pc {display: none !important;}
.tab-only {display: none !important;}

html body {
  font-size: 1.1rem;
  line-height: 1.5;
}

/* nav */
.nav_wrapper {
  position: fixed;
  top: 0;
  left: auto;
  right: -400px;
  width: 350px;
  height: 100%;
  overflow-y: auto;
  background-color: #373745;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  z-index: 999;
}
.nav_wrapper.open {
  right: 0px;
}
#mob_header {
  display: flex;
  width: 100%;
  justify-content: right;
}
#mob_nav_close {
  display: flex;
  justify-content: right;
  padding: 5px 10px 30px 10px;
}
nav .nav_wrapper ul {
  flex-wrap: wrap;
}
nav .nav_wrapper ul li {
  display: block;
  width: 100%;
}
nav .nav_wrapper ul li a {
  display: block;
  width: 100%;
  margin: auto;
  padding: 10px 15px;
}
nav .nav_wrapper ul > a {
  display: block;
  width: 100%;
  margin: auto;
  padding: 10px 15px;
}
nav[class*=Navbar_navbar] {
  padding: 10px 15px;
}
span.mob_nav_open_btn {
  display: flex;
}



/* nav ends */


header.App-header {
  min-height: unset;
  padding: 40px 0;
}
div[class*=Home_manifesto] {
  max-width: 100%;
}
div[class*=Home_home] {
  max-width: 100%;
}
div[class*=About_about] {
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
div[class*=Album_album] {
  padding: 30px 0px;
}
div[class*=Album_album] h2 {
  margin: 0 0 15px 0;
}
div[class*=Album_album] img {
  max-width: 100%;
  margin-top: 15px;
}
div[class*=LongAp_long-ap] {
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
p.not-found-prompt {
  font-size: 2rem;
}
div.not-found {
  padding-left: 20px;
  padding-right: 20px;
}
div[class*=Courses_courses] {
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
.App div[class*=CourseDetail_carousel] ul {
  padding: 0px;
  justify-content: center;
}
div[class*=Dashboard_dashboard] h2 {
  line-height: 1.5;
}
.App div[class*=StudyNote_study-note] {
  padding-left: 20px;
}





} /* /max-width:1023px ---------------------------------------------------------------- */


@media screen and (max-width: 767px) {

  .App > nav + div {
    padding-top: 60px;
    max-width: 100%;
}
div.CourseDetail_course-detail__eTmCR {
    margin-left: auto !important;
    padding-left: 15px;
    padding-right: 15px;
}
/* div[class*=Sidebar_sidebar__] {
    position: relative;
    height: auto;
    width: 100%;
    padding: 10px 15px;
    margin: 15px auto;
} */
div[class*=Unit_unit-content__] {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
}
div[class*=Module_carousel__] {
  margin: 15px 0;
}
div[class*=Module_carousel__] ul {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
    align-items: center;
    gap: 15px;
}
div#course_details_main {
  margin-left: 0px !important;
  padding-bottom: 50px;
}
div#itu_sidebar_footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: #333;
  display: flex;
  align-items: center;
  padding: 10px 15px;
}
div#itu_sidebar_footer span {
  display: flex;
}
div#itu_sidebar_main {
  position: fixed;
  top: 0;
  left: -400px;
  right: auto;
  top: 0;
  width: 350px;
  max-width: 100%;
  z-index: 99;
  height: calc(100% - 40px);
  overflow-y: auto;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
div#itu_sidebar_main.open {
  left: 0;
}
#itu_sidebar_main h3 {
  font-size: 1rem;
}
#itu_sidebar_main li {
  font-size: 1rem;
  margin-left: 0;
  margin-right: 0;
}






} /* /max-width:767px ----------------------------------------------------------------- */