.album {
    padding-top: 0;
    margin-top: 0;
    text-align: center;
}

.album h2 {
    font-size: 1.3em;
    padding: 3px 0;
    margin: 0 120px;
}

.album button {
    margin: 2px 5px;
    padding: 2px 35px;
    font-size: 0.9em;
    border: none;
    border-radius: 15px;
    box-shadow: none;
    color: rgb(0, 0, 0);
    background-color: rgb(138, 65, 34);
}

.album .label {
    margin: 3px auto 3px;
    padding: 0;
}

.album img {
    max-width: 768px;
    border-radius: 25px;
}

.album .hint {
    margin: 0 20px;
    font-size: 0.7em;
    font-weight: bold;
}