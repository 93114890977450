.lesson-block {
    border: 1px solid rgb(197, 183, 147);
    margin: 20px 60px 0 60px;
    padding: 2px 30px;
    border-radius: 20px;
    text-align: right;
    line-height: 0.5em;
}

.lesson-block label {
    color: rgb(130, 234, 186);
    font-size: 0.6em;
    display: inline-block;
    margin: 0 20px;
    padding: 0;
}

.remove-block {
    padding: 3px 10px;
    margin: 2px;
    box-shadow: none;
    border-radius: 5px;
    border: none;
    color: white;
    background: rgb(217, 67, 67);
}

.add-block {
    margin: 10px 100px;
    padding: 5px 10px;
    color: white;
    background: rgb(81, 35, 206);
    box-shadow: none;
    border-radius: 5px;
    border: none;
}