.form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form input {
  max-width: 360px;
  padding: 8px 6px;
  margin-right: 10px;
  border-radius: 7px;
  font-size: 1em;
  color: #777;
  width: 100%;
}

.form input::placeholder {
  color: #aaa;
}

.form label {
  margin-right: 10px;
}

.inlineForm {
  display: flex;
  align-items: center;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

/* Buttons in user dashboard */
.toggles {
  margin-bottom: 90px;
}

h2 {
  color: rgb(255, 65, 40);
  font-size: 1.2em;
}
