.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: var(--sidebar-width);
  padding: var(--navbar-height-offset) 0 0 10px;
  color: rgb(163, 163, 163);
  font-size: 0.7em;
  font-family: 'Montserrat';
  background: #005485;
  overflow-x: hidden;
  overflow-y: auto;
}
  
.sidebarHandle {
  position: absolute;
  top: 0;
  right: -5px;
  height: 100%;
  width: 10px;
  background-color: #da3f3f;
  z-index: 2;
}

.sidebar ul {
  list-style-type: none;
  padding: 0 0 ;
  margin: 0 0;
}

.sidebar li {
  cursor: pointer;
  margin: 6px;
  padding: 5px;
  line-height: 1.5em;
}

.sidebar .active {
  color: rgb(96, 244, 123);
  background:  #023350;
  /* font-weight: bold; */
  border-left: 7px solid rgb(36, 30, 28);
}
