.course-detail {
  padding: 0 40px;
}

.course-detail .location {
  color: #946f74;
  margin: 10px 0 0 0;
  padding: 0;
  line-height: 0.3em;
  font-family: "Montserrat";
  line-height: 1.2em;
}

.course-detail .prompt {
  color: rgb(140, 91, 247);
}

.course-detail .prompt a {
  color: rgb(252, 110, 63);
}

.course-detail .prompt a:visited {
  color: rgb(210, 72, 26);
}

.people h3,
.title {
  color: rgb(144, 129, 182);
  font-size: 0.7em;
  font-weight: bolder;
  padding: 3px 0;
  margin: 0;
  line-height: 0.8em;
}

.people li,
.person {
  color: rgb(121, 121, 121);
  list-style-type: none;
  font-size: 0.8em;
  padding: 3px 0;
  margin: 0;
  line-height: 0.7em;
  font-family: "Montserrat";
}

.carousel ul {
  /* font-size: 1em; */
  display: flex;
  align-items: center;
  list-style-type: none;
  color: rgb(204, 204, 204);
  font-size: 0.8em;
  font-weight: 800;
}

.carousel li {
  cursor: pointer;
  border-style: solid;
  border-width: 4px;
  border-radius: 10px;
  margin: 0 20px;
  padding: 2px 10px;
  background-color: rgb(167, 155, 139);
}

.carousel .active {
  border-width: 9px;
  border-color: rgb(255, 134, 78);
  font-size: 0.9em;
  font-weight: bold;
  color: rgb(0, 0, 0);
  background-color: rgb(255, 72, 55);
}

.carousel .hint {
  color: rgb(162, 34, 51);
  font-family: "Lexend";
  font-size: 2em;
}

.course-detail h2 {
  color: rgb(255, 65, 40);
  font-size: 2em;
}

.course-detail h3 {
  color: rgb(162, 135, 139);
  font-size: 0.7em;
}
