.poi {
    color: rgb(234, 226, 197);
    margin: 40px 80px;
}

.poi h2 {
    color:  rgb(242, 126, 144);
    font-size: 1.5em;
}

.poi h3 {
    color:  rgb(165, 85, 226);
    font-size: 0.8em;
}

.poi table {
    border-collapse: collapse;
  }
  


.poi th {
    color: #76582e; 
    font-size: 0.7em;
    border: 2px solid rgb(92, 144, 106); 
    padding: 4px; 
}

.poi td {
    border: 1px solid rgb(92, 144, 106); 
    padding: 8px; 
}


.poi a {
    color: rgb(230, 99, 55)
}

.poi a:visited {
    color: rgb(183, 90, 59)
}