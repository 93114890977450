.mod .desc {
    color: rgb(119, 91, 61);
    font-size: 0.8em;
    padding: 3px;
    margin: 0;
    font-family: 'Montserrat';
}

.carousel {
  font-size: 14pt;
}

.carousel ul {
  display: flex;
  align-items: center;
  padding: 0 auto;
  margin: 5px auto;
  list-style-type: none;
  color: gray;
}

.carousel li{
  cursor: pointer;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  margin: 0 10px;
  padding: 5px 10px;
}

.carousel .active {
  border-width: 2px;
  font-weight: bold;
  color: rgb(38, 231, 106);
}

.carousel .hint {
  color: rgb(164, 100, 109);
}

