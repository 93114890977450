/* 
* {
    background: linear-gradient(180deg,rgb(148, 182, 103) 20%,rgb(168, 85, 140) 100%);
} */

.home {  
    max-width: 125ch;
    margin: 20px auto;
    padding: 20px;
    line-height: 1.5;
    color:rgb(220, 168, 220);
    font-family: 'Montserrat';   
}

.manifesto {
    max-width: 1200px;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
}

/* .featured {
} */

.home ::selection {
    color: rgb(255, 233, 93);
    background-color: rgb(221, 68, 7);
}