.dashboard {
  max-width: 720px;
  margin: 40px auto;
  padding: 20px;
  line-height: 1.6em;
  color: rgb(107, 116, 165);
  font-size: .8em;
  font-family: 'Montserrat';
}

.metadata-section {
  margin-bottom: 90px;
}

.dashboard h2 {
  line-height: 0.35em;
  font-size: 1.5em;
}

.conformed {
  color: rgb(97, 74, 61);
  /*background: rgb(236, 93, 53);*/
  border-radius: 20px;
  font-weight: bold;
  font-size: 1.3em;
  line-height: 3em;
}

.conformed a {
  text-decoration: none;
  color: aquamarine;
}

.conformed a:visited {
  color: rgb(19, 191, 133);
}

.course-id {
    font-size: 0.6em;
    font-weight: bold;
    padding: 2px 8px;
    border-radius: 7px;
    color: rgb(36, 20, 20);
    background: #baf043;
}

.migration-non-existent {
  color: white;
  background: rgb(129, 82, 201);
  padding: 10px;
}
